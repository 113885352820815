import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ImageRenderer from '../ImageRenderer'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../SvgEl'
import LOGO from './../../svg/logos'

const BlogList = () => {

  const [jsonContent, setJsonContent] = useState('');
  var source_url = '';
  var newArr =[];
  function loadJson(url) {
  var _json = url;
  axios.get(_json)
  .then(function (response) {


  var _jsonContent = response.data.data.map( (item, index) => {
    var blogDate = new Date(item.date).toLocaleDateString();

    return (
      <div key={'d_' + item.id} className="row">
      <div className="col-sm-12 col-md-6 overflow-hidden">
      <ScrollAnimation animateOnce={true} animateIn="animate__animated animate__fadeInRight animate__delay-.2s">
      <a href={"/blog/" + item.slug}>
       <ImageRenderer
          styleclass="img-blog"
          url={source_url + item.image_thumb['url']}
          width={item.image_thumb['width'] }
          height={item.image_thumb['height']}
          alt_tag={'Lilley Mansion ' +item.title}
          />
          </a>
           </ScrollAnimation>
          </div>
      <div className="col-sm-12 col-md-6 blog-card overflow-hidden">
      <ScrollAnimation animateOnce={true} animateIn="animate__animated animate__fadeInUp animate__delay-.5s">
      <a href={"/blog/" + item.slug}>
      <div className="p-3 text-center">
      <div className="blog-logo">
      <SvgEl svg={LOGO.MAIN} className=''/>
      </div>
      <span className="pt-0 mt-0 date-blog">{blogDate}</span>
      <h3 className="ttl-blog pb-2 mb-1">{item.title}</h3>
      <div className="excerpt-blog text-center mt-2" dangerouslySetInnerHTML={{ __html: item.excerpt}}/>
      </div>
      </a>
      </ScrollAnimation>
     </div>
     <p>&nbsp;</p>
     </div>
   );

 });
  //device = response.data.device;
  //newArr = 'xxxx'; //response.data.data.map(itemsStructure);

 setJsonContent(_jsonContent);

//return newArr;


  });
  }




  useEffect(() => {

    //console.log('loaded')
      loadJson('https://discover.lilleymansion.com/api/v1/blog');
  },[]);


  return (
    <div
      className="blog-outer">
      {jsonContent}
    </div>
  );
};

export default BlogList;
