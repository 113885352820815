import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"

import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from './../../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'

import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
//import SvgEl from './../../components/SvgEl'
//import LOGO from './../../svg/logos'
import BlogList from './../../components/BlogList';




gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const BlogPage = ({ data }) => {

//  console.log(data.photos.edges);


  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
    var device = '';


  useEffect(() => {
    setIsMobile(mobile);
  //  console.log('loaded')

  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper">


    <div className="container pt-4 p-2 text-center">
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp animate__delay-.3s">
    <h1 className="titling">BLOG</h1>
    </ScrollAnimation>
    </div>

      <BlogList/>


  {/*
    <div className="imageFullWidth">
  { isMobile && data.photos.edges.length > 1 ?

      <GatsbyImage
       fluid={data.photos.edges[1].node.childImageSharp.fluid}
       alt={data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
       image={getImage(data.photos.edges[1].node)}
       />
       :
       <GatsbyImage
        fluid={data.photos.edges[0].node.childImageSharp.fluid}
        alt={data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
        image={getImage(data.photos.edges[0].node)}
        />
    }


    </div>
    */}


    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

BlogPage.Layout = Layout

export default BlogPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
